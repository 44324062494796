.woocommerce div.product div.summary,
.woocommerce-page div.product div.summary,
.woocommerce #content div.product div.summary,
.woocommerce-page #content div.product div.summary {
  float: initial;
  width: 100%;
  clear: initial;
}

.accordion-body form.cart {
  margin-bottom: 0 !important;
}

.accordion-body .quantity {
  margin-right: 20px !important;
}

.product .accordion-button a.base-link {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.woocommerce a.added_to_cart {
  padding-left: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:focus,
.btn-check:focus + .btn-primary {
  color: #fff;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: map-get($theme-colors, "secondary");
  background-color: #ebebeb;
  font-weight: 700;
  border-top: 3px solid map-get($theme-colors, "primary");
}

.woocommerce-info {
  background-color: map-get($theme-colors, "primary");
}

.flux-checkout__cart-image {
  display: none;
}

.flux-step #order_review tr.cart_item td.product-name,
.flux-checkout__content-right #order_review tr.cart_item td.product-name {
  padding-left: 0 !important;
}

html,
body,
.flux-checkout,
.flux-checkout__content,
.flux-checkout__content-wrapper,
.flux-checkout__content > .woocommerce {
  min-height: auto !important;
}

.header__title {
  font-size: 2.7rem !important;
}

.flux-checkout__spinner {
  z-index: 9999999999;
}

input[type=checkbox]:checked::after {
  height: 10px;
  left: 5px;
  top: 3px;
}

/**
 * My Account
 */

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 20%;
}

.woocommerce-account .woocommerce-MyAccount-content {
  display: inline-block;
  float: left;
  margin-left: 5%;
  width: 75%;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul {
  padding: 0;
  border-top: 1px solid #eee;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li {
  list-style: none;
  border-bottom: 1px solid #eee;
  padding-bottom: 0;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li a {
  padding: 10px 5px;
  display: block;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li a::before {
  margin-right: 20px;
  opacity: 0.25;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.is-active a::before {
  opacity: 1;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li a:hover::before {
  opacity: 1;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--dashboard a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f0e4";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--orders a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f291";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--downloads a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f1c6";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--edit-address a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f015";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f09d";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--edit-account a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f007";
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--customer-logout a::before {
  font-family: FontAwesome, sans-serif;
  content: "\f08b";
}

.related > h2 {
  font-size: 1.95em;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.woocommerce-account .woocommerce-MyAccount-content h2 {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}

.woocommerce-account p.order-again {
  text-align: right;
}

.woocommerce-account .woocommerce-EditAccountForm input[type="submit"] {
  margin-top: 50px;
  float: right;
}

.woocommerce-order .woocommerce-notice--success {
  @extend .alert;

  background-color: map-get($theme-colors, "primary");
  border-left: 5px solid map-get($theme-colors, "secondary");
  color: #fff;
}

.woocommerce-table thead th {
  background-color: map-get($theme-colors, "secondary");
  color: #fff;
}

.woocommerce-table tfoot th,
.woocommerce-table tfoot td {
  background-color: lighten(map-get($theme-colors, "greyish"), 32);
}

.woocommerce-table {
  border-spacing: 0;
  border-collapse: separate;
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email,
.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone {
  padding-left: 3.5em !important;
  padding-right: 3.5em !important;
}

section.woocommerce-order-details {
  width: 100%;
  padding: 30px;
  background: #fff;
  position: relative;
  display: inline-block;
  box-shadow: 0 0.5rem 1rem 0.5rem rgba(0, 0, 0, 0.1);
}

section.woocommerce-order-details::after {
  background-image: linear-gradient(135deg, #fff 0.5rem, transparent 0), linear-gradient(-135deg, #fff 0.5rem, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 1rem;
  content: "";
  display: block;
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1rem;
}

section.woocommerce-order-details tbody {
  background-color: #fff;
}

.woocommerce .woocommerce-customer-details address {
  display: flex;
}

.group-parent {
  border-left: 5px solid map-get($theme-colors, "primary");
}

.group-child {
  border-left: 5px solid map-get($theme-colors, "secondary-light");
}

.woocommerce div.product form.cart .button {
  padding: 20px 30px !important;
  background-color: map-get($theme-colors, "primary");
}

.alt-product .quantity .qty {
  padding: 15px !important;
  border: 1px solid #cdcdcd !important;
  width: 6.631em !important;
  border-radius: 5px;
}

.alt-product .product_title {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 1.6rem;
  color: map-get($theme-colors, "secondary");
}

.single_add_to_cart_button {
  padding: 14px 30px !important;
  flex-grow: 1;
}

.woocommerce-variation-add-to-cart {
  display: flex;
  flex-grow: 1;
}

.woocommerce-variation-add-to-cart .single_add_to_cart_button {
  flex-grow: 1;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
  color: map-get($theme-colors, "primary") !important;
}

.woocommerce-product-details__short-description {
  margin-top: 40px;
}

.tc-epo-text-wrapper {
  border-radius: 5px;
  border-color: #cdcdcd;
  padding: 10px 15px !important;
}

.woocommerce div.product div.images.woocommerce-product-gallery {
  width: 100% !important;
}

.related .product img {
  object-fit: contain !important;
  height: 100% !important;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) img {
  border-radius: 5px;
  mix-blend-mode: multiply;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(1) {
  float: right;
  width: 80%;
  background-color: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  width: 15%;
  background-color: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  margin-bottom: 10px;
}

.woocommerce .related ul.products li.product,
.woocommerce-page .related ul.products li.product {
  height: 260px !important;
  border-radius: 10px;
  background-color: #f2f2f2;
}

.summary .price {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.woocommerce .related .price {
  font-size: 1.2em !important;
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.woocommerce-loop-product__title {
  font-size: 1.4em !important;
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.woocommerce .related ul.products li.product .button {
  margin-bottom: 15px;
  padding: 15px 20px !important;
  background-color: map-get($theme-colors, "primary") !important;
  color: #fff !important;
}

.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt.disabled,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt.disabled:hover {
  background-color: map-get($theme-colors, "primary") !important;
}

.woocommerce-variation-price {
  display: none;
}

.loading::after {
  font-family: FontAwesome, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  content: "\f1ce" !important;
}

.woocommerce div.product form.cart .variations select {
  padding: 10px;
  border-radius: 5px;
}

.woocommerce div.product form.cart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.single_variation_wrap {
  align-items: center;
  display: flex;
  flex-grow: 1;
}
