$theme-colors: (
  primary: #99c1e8,
  secondary: #142744,
  secondary-light: #2c466d,
  greyish: #989898,
  danger: #c85546,
  warning: #f8ae4e,
  greyc: #e5e5e5,
  maingrey: #989898,
  newblue: #99c1e8,
);

$container-max-widths: (
  xl: 1440px
);
