@media only screen and (min-width: 320px) and (max-width: 500px) {
  h1 {
    font-size: 2rem;
  }

  .btn {
    padding: 5px 20px;
  }

  .parts-form {
    background-size: 40px !important;
  }

  header.banner {
    margin-bottom: 3rem;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }

  .product-list h3,
  .product-list .h3 {
    font-size: 1rem;
  }

  .prices-table thead {
    display: none !important;
  }

  .prices-table th,
  .prices-table td {
    width: 100% !important;
    display: block !important;
  }

  .product-list .type {
    font-size: 0.8rem;
  }

  .ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
    font-size: 2.2rem !important;
  }

  .brand,
  .brand-light {
    display: block;
    width: 40px;
    position: absolute;
  }

  .bottom-gap {
    margin-bottom: 8rem;
  }

  .product-image {
    height: 200px;
  }

  .product-post h1,
  .product-post .h1 {
    font-size: 2.3rem;
  }

  .product-post h4,
  .product-post .h4 {
    font-size: 1.1rem;
  }

  .pro-tip {
    font-size: 0.8rem;
  }

  .main-image-large {
    height: 410px;
  }

  .max-tip-box {
    margin-left: 0;
    margin-top: 5%;
    position: relative;
  }

  .main-title-blog h1,
  .main-title-blog .h1 {
    font-size: 2rem;
  }

  .main-title-blog {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }

  #results h3,
  #results .h3 {
    font-size: 1.8rem;
  }

  #results h5,
  #results .h5 {
    font-size: 1rem;
  }

  .newsletter .gform_wrapper.gravity-theme .gfield.gfield--width-half {
    grid-column: span 8 !important;
  }

  .newsletter #field_submit {
    grid-column: span 4 !important;
  }

  .menu-overlay h2,
  .menu-overlay .h2 {
    font-size: 2.4rem;
  }

  .prem-img {
    margin-top: 0;
  }

  .single-locations header.banner,
  .single-format-standard .banner {
    margin-bottom: 0 !important;
  }

  .tm-extra-product-options ul.tmcp-ul-wrap li.tmcp-field-wrap > label {
    flex-grow: 1;
  }

  .tc-epo-text-wrapper {
    justify-content: center;
  }

  .woocommerce .related ul.products li.product,
  .woocommerce-page .related ul.products li.product {
    height: auto !important;
    padding: 10px;
  }

  .featuretitle {
    width: 90%;
  }

  ul.bulletlist {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    padding: 15px;
  }

  ul.bulletlist li {
    list-style: none;
    position: relative;
    padding-left: 25px;
  }

  ul.bulletlist li::before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f00c";
    font-size: 16px;
    color: map-get($theme-colors, "primary");
    padding-right: 5px;
    font-weight: 900;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 844px) {
  .nesting {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .prices-table thead {
    display: none !important;
  }

  .prices-table th,
  .prices-table td {
    width: 100% !important;
    display: block !important;
  }

  .tm-extra-product-options ul.tmcp-ul-wrap li.tmcp-field-wrap > label {
    flex-grow: 1;
  }

  .tc-epo-text-wrapper {
    justify-content: center;
  }

  .woocommerce .related ul.products li.product,
  .woocommerce-page .related ul.products li.product {
    height: auto !important;
    padding: 10px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 995px) {
  .question-circle,
  .question-circle-outline,
  .question-circle-submit {
    width: 170px !important;
    height: 170px !important;
  }

  .prices-table thead {
    display: none !important;
  }

  .prices-table th,
  .prices-table td {
    width: 100% !important;
    display: block !important;
  }

  .max-tip-box {
    margin-left: 0;
    margin-top: 5%;
    position: relative;
  }

  .tm-extra-product-options ul.tmcp-ul-wrap li.tmcp-field-wrap > label {
    flex-grow: 1;
  }

  .tc-epo-text-wrapper {
    justify-content: center;
  }

  .woocommerce .related ul.products li.product,
  .woocommerce-page .related ul.products li.product {
    height: auto !important;
    padding: 10px;
  }
}
