/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.login-username,
.login-password {
  position: relative;
  margin-bottom: 25px;
}

.login-username label,
.login-password label {
  display: block;
}

.login-username input,
.login-password input {
  border: 0;
  background: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  color: map-get($theme-colors, "secondary");
}

.login-username input:focus,
.login-password input:focus {
  outline: none;
  border: none;
}

.login-submit #wp-submit {
  border: 1px solid #fff;
  width: 100%;
  background: transparent;
  padding: 10px 25px;
  color: #fff;
  border-radius: 30px;
}

.login-submit #wp-submit:hover {
  border: 1px solid #fff;
  width: 100%;
  background: #fff;
  padding: 10px 25px;
  color: map-get($theme-colors, "secondary");
  border-radius: 30px;
}

.gform-body input[type="text"],
.gform-body input[type="password"],
.gform-body input[type="email"],
.gform-body input[type="tel"],
.gform-body textarea,
.gform-body select {
  border: 1px solid lighten(map-get($theme-colors, "greyish"), 25);
  padding: 9px !important;
  border-radius: 30px;
}

#gform_submit_button_1 {
  padding: 13.3px;
  border-radius: 0;
  border: 1px solid map-get($theme-colors, "primary");
  font-weight: 700;
  width: 100%;
}

.newsletter .gform_wrapper.gravity-theme .gfield.gfield--width-half {
  grid-column: span 9 !important;
}

.newsletter #field_submit {
  grid-column: span 3 !important;
}

.newsletter #field_submit input {
  text-align: center !important;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  border-radius: 30px;
  font-size: 1rem;
  padding: 9px !important;
}

#gform_submit_button_5,
#gform_submit_button_2 {
  text-align: center !important;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 9px !important;
  width: 100%;
}

#media-page .login-remember #rememberme {
  display: inline-block !important;
  width: auto !important;
  height: auto !important;
}

#media-page .login-submit #wp-submit {
  text-align: center !important;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 9px !important;
  width: 100%;
}

#media-page .login-submit #wp-submit:hover {
  background-color: map-get($theme-colors, "secondary");
  border-color: map-get($theme-colors, "secondary");
}

#media-page .login-username input,
#media-page .login-password input {
  border: 1px solid map-get($theme-colors, "secondary");
}

.woocommerce-Input {
  border: 1px solid lighten(map-get($theme-colors, "greyish"), 25);
  padding: 9px !important;
  border-radius: 30px;
}
