.btn {
  border-radius: 30px !important;
  padding: 5px 30px;
  font-size: 1rem !important;
}

.btn-white {
  border-color: #fff;
  color: #fff;
}

.btn-grey {
  border-color: map-get($theme-colors, "maingrey") !important;
  background-color: map-get($theme-colors, "maingrey") !important;
  color: #fff;
}

.btn-grey:hover {
  border-color: map-get($theme-colors, "maingrey") !important;
  background-color: map-get($theme-colors, "maingrey") !important;
  color: #fff;
}

.btn-white-solid {
  border-color: #fff;
  background-color: #fff;
  color: map-get($theme-colors, "secondary");
}

.menu-overlay .btn-white:hover {
  border-color: #fff;
  color: #fff;
}

.btn-primary {
  color: #fff;
}

.btn-primary:hover {
  color: map-get($theme-colors, "primary") !important;
  background-color: #fff;
  border-color: map-get($theme-colors, "primary");
}

.btn-secondary-light {
  color: #fff;
  background-color: lighten(map-get($theme-colors, "secondary"), 5);
  border-color: lighten(map-get($theme-colors, "secondary"), 5);
}

.btn-secondary-light:hover {
  color: map-get($theme-colors, "secondary");
  background-color: #fff;
  border-color: #fff;
}

.location-listing-qtech .btn-primary:hover,
.product-list .btn-primary:hover,
.location-details .btn-primary:hover,
.main-link.btn-primary:hover {
  color: #fff;
  background-color: map-get($theme-colors, "secondary");
  border-color: map-get($theme-colors, "secondary");
}

.btn-danger {
  color: #fff;
  background-color: map-get($theme-colors, "danger");
  border-color: map-get($theme-colors, "danger");
}

.btn-danger:hover {
  color: darken(map-get($theme-colors, "danger"), 10) !important;
  background-color: #fff !important;
  border-color: darken(map-get($theme-colors, "danger"), 10) !important;
}

.btn-danger:hover i {
  color: darken(map-get($theme-colors, "danger"), 10) !important;
}

.btn-warning {
  color: map-get($theme-colors, "secondary") !important;
  background-color: map-get($theme-colors, "warning");
  border-color: map-get($theme-colors, "warning");
}

.btn-warning:hover {
  color: darken(map-get($theme-colors, "secondary"), 10) !important;
  background-color: #fff !important;
  border-color: darken(map-get($theme-colors, "warning"), 10) !important;
}

.btn-warning:hover i {
  color: darken(map-get($theme-colors, "secondary"), 10) !important;
}

.button,
#place_order,
.flux-step__button {
  border-radius: 30px !important;
  padding: 5px 30px !important;
  font-size: 1rem !important;
}

.woocommerce-info button {
  border-color: #fff !important;
  background-color: #fff !important;
  color: map-get($theme-colors, "secondary") !important;
}

.aws_cart_button,
.woocommerce-button,
.add_to_cart_button {
  @extend .btn;
  @extend .btn-primary;

  height: auto !important;
  color: #fff !important;
  background-color: map-get($theme-colors, "primary") !important;
}

.add_to_cart_button {
  padding: 10px 30px !important;
  display: block !important;
  margin-top: 1rem !important;
}

.add_to_cart_button:hover {
  background-color: map-get($theme-colors, "secondary") !important;
  color: #fff !important;
}

.woocommerce div.product span.price {
  font-size: 1rem;
}

.aws_cart_button a {
  color: #fff !important;
}

.aws-search-result .aws_add_to_cart .aws_quantity_field {
  height: 32px !important;
}

.btn-danger * {
  color: #fff !important;
}

.btn-blue {
  color: #fff;
  background-color: lighten(map-get($theme-colors, "newblue"), 5);
  border-color: lighten(map-get($theme-colors, "newblue"), 5);
}

.btn-blue:hover {
  color: map-get($theme-colors, "newblue");
  background-color: #fff;
  border-color: #fff;
}
