@font-face {
  font-family: "Gotham";
  src:
    url("../../fonts/gotham/Gotham-Light.woff2") format("woff2"),
    url("../../fonts/gotham/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Bold";
  src:
    url("../../fonts/gotham/Gotham-Bold.woff2") format("woff2"),
    url("../../fonts/gotham/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src:
    url("../../fonts/gotham/Gotham-Book.woff2") format("woff2"),
    url("../../fonts/gotham/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gotham_blackregular";
  src:
    url("../../fonts/gotham/gotham_black_regular-webfont.woff2") format("woff2"),
    url("../../fonts/gotham/gotham_black_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.checkout footer * {
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  font-size: auto !important;
}

.checkout #menu-block * {
  color: #fff !important;
  line-height: 1.4 !important;
}

.white-text {
  color: #fff !important;
}

html,
body {
  font-family: Gotham Book, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: normal;
  font-style: normal;
  top: 0 !important;
  overflow-x: hidden;
  font-size: 0.9rem;
}

h1 {
  font-size: 2.8rem;
}

p {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: map-get($theme-colors, "secondary");
}

a:hover {
  text-decoration: none;
  color: map-get($theme-colors, "primary");
}

.green {
  color: map-get($theme-colors, "primary");
}

.tp-thumb-mask {
  overflow: visible !important;
}

.quote-box {
  -webkit-box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.1);
}

.quote-box-bottom::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 15px;
  width: 0;
  border-top: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.quote-box-left::before {
  content: "";
  position: absolute;
  top: 27%;
  left: -13px;
  width: 0;
  border-top: 13px solid transparent;
  border-right: 13px solid #fff;
  border-bottom: 13px solid transparent;
}

.quote-box-right::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -13px;
  top: 20%;
  height: 0;
  width: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid transparent;
}

.quote-box-right::after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -13px;
  top: 20%;
  height: 0;
  width: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid #fff;
}

.number-circles {
  background-color: map-get($theme-colors, "secondary");
  width: 50px;
  border-radius: 25px;
  color: #fff;
  height: 50px;
  padding: 16px 14px;
  display: inline-block;
  margin-right: 10px;
}

.wp-block-themepunch-revslider {
  position: relative;
  border-bottom: 2px solid #f0f0f0;
}

.bold,
b,
strong {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.offer-block,
.offer-block-2 {
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
}

.menu-overlay {
  background-color: map-get($theme-colors, "secondary");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  color: #fff;
  overflow-y: scroll;
  display: none;
}

.menu-overlay h2 {
  font-size: 2.4rem;
}

.menu-overlay h3 {
  font-size: 3.125rem;
  color: map-get($theme-colors, "primary");
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.menu-overlay h4 {
  color: map-get($theme-colors, "primary");
}

.menu-active {
  display: block !important;
}

.addresses a {
  color: #fff;
}

.addresses a:hover {
  color: map-get($theme-colors, "primary");
}

#blog-content a,
.link-line {
  text-decoration: underline !important;
  text-underline-position: under !important;
  color: #000 !important;
}

.ubermenu .ubermenu-target {
  padding: 0 15px !important;
}

#ubermenu-main-3-primary_navigation-2.ubermenu .ubermenu-target {
  padding: 15px 0 !important;
}

.prem-img {
  margin-top: -45%;
}

.ddc {
  background-color: #eef2f4;
}

.jdc {
  background-color: #003d7f;
}

.kbdc {
  background-color: #1f006c;
}

.bottom-gap {
  margin-bottom: 8rem;
}

.single-locations .bottom-gap,
.single-post .bottom-gap {
  margin-bottom: 0 !important;
}

#filter {
  padding: 30px;
  border-radius: 0 5px 5px 0;
  -webkit-box-shadow: 0 0 21px 5px rgb(0 0 0 / 16%);
  box-shadow: 0 0 21px 5px rgb(0 0 0 / 16%);
}

#locate-btn {
  position: absolute;
  right: 5%;
  top: 50%;
}

.ajax-loader-list {
  display: none;
}

.ajax-loader-list img {
  width: 40px;
}

#preloader-locate {
  width: 20px;
}

.location-listing-qtech h3 {
  color: map-get($theme-colors, "primary") !important;
}

.location-listing-qtech h5 {
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.location-listing-qtech .distance {
  color: map-get($theme-colors, "greyish");
  font-size: 1rem;
}

.product-list .type {
  color: #000;
  font-size: 1rem;
}

#title-animation {
  width: 70px;
  display: inline;
  margin-top: -10px;
  margin-left: 30px;
  position: absolute;
}

.product-list h3 {
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 1.2rem;
}

#map {
  width: 100%;
  height: 667px;
  z-index: 0;
}

.location-details {
  position: absolute;
  margin-top: 10rem;
  z-index: 1;
}

.box-map {
  background-color: #fff;
  -webkit-box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.1);
}

.box-map h1 {
  font-size: 1.563rem;
  color: map-get($theme-colors, "secondary");
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.box-map .address {
  color: map-get($theme-colors, "greyish");
}

.call-no {
  font-size: 1.2rem;
  color: map-get($theme-colors, "primary");
}

.box-highlight {
  border-top: 5px solid map-get($theme-colors, "primary");
}

.posts-list {
  font-size: 2.125rem;
  color: map-get($theme-colors, "secondary");
}

.profile .name {
  font-size: 14px;
}

.profile .job {
  font-size: 12px;
  color: map-get($theme-colors, "greyish");
}

.overlay {
  background-color: rgba(20, 39, 68, 0.25);
}

.product-image {
  object-fit: contain;
  max-height: 200px;
  height: 200px;
}

.main-image-large {
  object-fit: contain;
  max-height: 500px;
  height: 500px;
}

.product-post h1 {
  font-size: 2.75rem;
  color: map-get($theme-colors, "secondary");
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.product-post h4 {
  font-size: 1.388rem;
  color: map-get($theme-colors, "greyish");
}

.product-post h5 {
  font-size: 1.375rem;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: map-get($theme-colors, "primary");
}

.greyish {
  color: map-get($theme-colors, "greyish");
}

.max-tip-box {
  background-color: map-get($theme-colors, "secondary");
  color: #fff;
  border-radius: 20px;
  margin-left: -75px;
  position: absolute;
}

.max-tip-box h6 {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 3.375rem;
}

.max-tip-box p {
  font-size: 1.375rem;
}

.max-tip-box-alt {
  color: map-get($theme-colors, "secondary");
  border-radius: 20px;
  margin-left: 0;
  position: absolute;
}

.max-tip-box-alt h6 {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 3.375rem;
}

.max-tip-box-alt p {
  font-size: 1.375rem;
}

.featuretitle {
  margin-top: -20px;
  width: auto;
  margin-left: 15px;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 30px !important;
  text-align: left;
  font-size: 1rem !important;
  padding: 5px 20px;
  color: #fff;
}

#results {
  display: none;
}

.main-title-blog {
  padding-top: 10rem;
  padding-bottom: 6rem;
  color: #fff !important;
}

.main-title-blog .job {
  color: #fff !important;
}

.main-title-blog h1 {
  color: #fff;
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

hr {
  background-color: #dae2e6;
  opacity: 1;
}

.servicing .wp-block-themepunch-revslider {
  border-bottom: none !important;
}

.pro-tip {
  font-size: 1rem;
}

.vc-gitem-zone-height-mode-auto::before {
  background: linear-gradient(0deg, rgba(15, 40, 89, 1) 0%, rgba(15, 40, 89, 0) 54%) !important;
}

#wtb {
  padding: 10px 30px !important;
}

.product-list-associated h3 {
  font-size: 0.85rem;
}

.product-image-associated {
  max-height: 130px;
  height: 130px;
  object-fit: contain;
}

.widget {
  @extend .card;
  @extend .mb-3;
}

.wp-block-search__label,
.wp-block-group__inner-container h2 {
  @extend .card-header;
}

.wp-block-group__inner-container ol {
  @extend .list-group;
  @extend .list-group-flush;
}

.wp-block-group__inner-container ul {
  @extend .list-group;
  @extend .list-group-flush;
}

.wp-block-group__inner-container li {
  @extend .list-group-item;
}

.wp-block-search__inside-wrapper,
.no-comments,
.wp-block-tag-cloud {
  @extend .p-3;
}

.accordion-button::after {
  order: -1;
  margin-left: 0;
  margin-right: 1em;
  width: 2.5rem;
  background-position: center;
  height: 2.5rem;
  border: 1px solid map-get($theme-colors, "secondary");
  border-radius: 50%;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.card-active {
  box-shadow: inset 0 -5px 0 map-get($theme-colors, "primary") !important;
}

.number {
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 1.3rem;
  border-radius: 50%;
  background-color: map-get($theme-colors, "secondary");
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.part-header {
  display: flex;
  align-items: center;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: map-get($theme-colors, "primary");
}

.aws-container .aws-search-form {
  height: 71px;
  border: 4px solid #e0e0e0;
}

.aws-container .aws-search-field {
  border: none;
}

.aws-container .aws-search-form .aws-form-btn {
  background: none !important;
  border: none !important;
}

.card h3 {
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: map-get($theme-colors, "secondary");
  font-size: 1.3rem;
}

.parts-form {
  width: 100% !important;
  border: 5px solid map-get($theme-colors, "primary") !important;
  border-radius: 0 !important;
  padding: 15px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(https://www.qtechspray.com/wp-content/themes/qtech/resources/assets/images/down-arrow-black.svg) no-repeat calc(100% - 5px) transparent;
}

.genuine-parts-selector .aws-container .aws-search-form {
  width: 100% !important;
  border: 5px solid map-get($theme-colors, "primary") !important;
  border-radius: 0 !important;
  padding: 15px !important;
  -webkit-appearance: none;
  height: 61px;
  -moz-appearance: none;
}

.genuine-parts-selector .aws-container .aws-search-field {
  font-size: 14px !important;
  padding: 6px 40px 6px 0 !important;
  color: #000 !important;
}

.genuine-parts-selector .aws-container .aws-search-field::placeholder {
  color: #000 !important;
}

.orcon {
  width: 100% !important;
  text-align: center !important;
  border-bottom: 1px solid #c7c7c7 !important;
  line-height: 0.1em !important;
  margin: 10px 0 20px !important;
}

.orcon span {
  background: #fff !important;
  padding: 0 10px !important;
  position: relative !important;
  top: 10px !important;
}

rs-static-layers {
  overflow: visible !important;
}

.mob-checkout {
  padding: 10px 20px !important;
}

.modal-body img {
  max-height: 600px !important;
}

.modal-backdrop {
  z-index: 88889 !important;
}

.modal {
  z-index: 88890 !important;
}

.search-price-box {
  background-color: #eef0ec;
  border-radius: 20px;
}

.dropdown-label {
  position: absolute;
  left: 55px;
  top: 10px;
  font-size: 9px;
}

#sku-search {
  position: relative;
}

.sku-search-icon {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 12px;
}

.dropdown-icon {
  position: absolute;
  z-index: 10;
  right: 30px;
  top: 15px;
}

.price-box {
  background-color: #eef0ec;
  border-radius: 3px;
}

.price-title {
  font-size: 18px;
}

.price-code {
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 20px;
  color: map-get($theme-colors, "secondary");
  font-weight: 700;
}

ul.bulletlist {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding: 15px;
}

ul.bulletlist li {
  list-style: none;
  position: relative;
  padding-left: 25px;
}

ul.bulletlist li::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\f00c";
  font-size: 16px;
  color: map-get($theme-colors, "primary");
  padding-right: 5px;
  margin-left: -20px;
  font-weight: 900;
}

#jckqv {
  max-width: 1200px !important;
}
