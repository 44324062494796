.banner .nav li {
  @extend .nav-item;
}

.blue-header a:not(.btn-warning) {
  color: map-get($theme-colors, "secondary") !important;
}

.blue-header .acc a {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.blue-header i:not(.btn-warning i),
.blue-header b,
.blue-header div,
.blue-header span {
  color: map-get($theme-colors, "secondary");
}

.banner .nav a {
  @extend .nav-link;
}

.blue-bar {
  background-color: map-get($theme-colors, "primary");
  padding: 3px;
  margin-bottom: 2px;
}

.brand {
  z-index: 10;
}

.brand img {
  width: 70px;
  margin-top: 6px;
}

.brand-light img {
  width: 70px;
  margin-top: 6px;
}

header.banner {
  padding: 10px;
  z-index: 1000;
  position: relative;
}

.green-header {
  background-color: #fff;
  min-height: 57px;
  position: absolute;
  top: 0;
  left: 0;
}

.blue-header {
  background-color: #fff;
  padding: 15px 0;
  position: absolute;
  top: 57px;
  color: map-get($theme-colors, "secondary");
  left: 0;
  pointer-events: auto;
}

.bf-header {
  padding: 15px 0;
  position: absolute;
  top: 67px;
  color: #fff;
  left: 0;
  pointer-events: auto;
}

.offer-block h2 {
  font-size: 50px !important;
  line-height: 50px;
  text-align: center !important;
  font-family: "Gotham Bold", sans-serif;
}

.offer-block-2 h2 {
  font-size: 50px !important;
  line-height: 50px;
  text-align: center !important;
  font-family: "Gotham Bold", sans-serif;
}

.offer-block p {
  text-align: center !important;
  line-height: normal !important;
  font-size: 25px !important;
  font-family: "Gotham Book", sans-serif;
}

.offer-block-2 p {
  text-align: center !important;
  line-height: normal !important;
  font-size: 25px !important;
  font-family: Gotham Book, sans-serif;
}

.page-template-bf-page {
  background-color: #1c1d1d;
}

.page-template-bf-page .bf-header a {
  color: #fff !important;
}

.grey-header {
  background-color: map-get($theme-colors, "greyc");
  pointer-events: auto;
}

.bar-menu {
  color: map-get($theme-colors, "secondary");
  font-size: 1.2rem;
}

.bar-menu:hover {
  color: map-get($theme-colors, "primary");
}

.which-back {
  display: none;
}

.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  font-size: 2.375rem;
  color: #fff;
}

.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target span {
  font-size: 100% !important;
}

.line-under {
  border-bottom: 1px solid map-get($theme-colors, "greyc");
}

.wrap.mt-5 {
  margin-top: 4rem !important;
}
