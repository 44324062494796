#selections h4 {
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.7rem;
}

#selections p {
  font-size: 1.2rem;
}

#selections a:hover {
  color: map-get($theme-colors, "primary");
  text-decoration: none;
}

.question-circle {
  height: 200px !important;
  width: 200px !important;
  border-radius: 50%;
  color: #fff;
  background-color: black;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 30px;
}

.question-circle-outline {
  height: 200px !important;
  width: 200px !important;
  border-radius: 50%;
  color: #000;
  background-color: white;
  border: 5px solid #cdcdcd;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 30px;
}

.question-circle-submit {
  height: 200px !important;
  width: 200px !important;
  border-radius: 50%;
  color: #fff;
  background-color: map-get($theme-colors, "secondary");
  border: 5px solid map-get($theme-colors, "secondary");
  display: table-cell;
  vertical-align: middle;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 30px;
}

.question-circle-outline:hover {
  text-decoration: none;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

button:disabled {
  opacity: 0.5;
}

.active:not(.stars .active) {
  text-decoration: none;
  background-color: map-get($theme-colors, "primary");
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.question-circle-submit:hover {
  text-decoration: none;
  border-color: map-get($theme-colors, "primary");
  color: #fff;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

#heavy-use {
  background-color: #fff;
  padding: 30px;
  font-size: 1.2rem;
}

.product_name_high,
.product_name_low {
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

#results h3 {
  font-size: 1.8rem;
  margin: 0;
}

#results h5 {
  font-size: 1.1rem;
  margin: 0;
}

#results .cta {
  background-color: map-get($theme-colors, "primary");
  display: block;
  padding: 18px 30px;
  border-radius: 12px;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

#results .cta:hover {
  background-color: map-get($theme-colors, "secondary");
  text-decoration: none;
}

.essential {
  background-color: map-get($theme-colors, "secondary");
  border-radius: 12px;
  color: #fff;
}

.essential-alt {
  border-radius: 0;
  color: map-get($theme-colors, "secondary");
}

.alt-bkg {
  background-color: #ededed;
}

.essential-alt .val {
  font-size: 3rem;
  line-height: 2.5rem;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.essential-alt hr {
  border-top: 1px solid #e2e4e3;
  margin: 0;
}

.essential-alt .desc {
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.essential .val {
  font-size: 3rem;
  line-height: 2.5rem;
  font-family: Gotham Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.essential hr {
  border-top: 1px solid #e2e4e3;
  margin: 0;
}

.essential .desc {
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.p-30 {
  padding: 24px 15px;
}

#standard-use {
  padding: 20px;
  font-size: 1rem;
}

.mh {
  height: 300px;
}
